export default {
  "bank-account-option": "qEZ",
  "row": "qER",
  "active": "qEQ",
  "animated": "qEe",
  "fadein-item": "qEB",
  "cell": "qEE body-2",
  "cell-content": "qEd",
  "subtitle": "qEI caption",
  "no-padding": "qEt",
  "empty": "qEM",
  "note": "qEP",
  "cell-content-amount": "qEl body-1",
  "cell-content-receipt": "qEX body-1",
  "cell-quick-actions": "qEC",
  "account-selector": "qEk",
  "cell-type": "qEJ"
};
