export default {
  "cell": "qQB",
  "supplier-container": "qQE",
  "overflow": "qQd",
  "cell-label-container": "qQI",
  "cell-label": "qQt",
  "missing": "qQM",
  "flex-label": "qQP",
  "sub-label": "qQl body-2",
  "with-separator": "qQX",
  "file-name-warning": "qQC",
  "self-invoice-icon": "qQk"
};
