export default {
  "cell": "qQJ",
  "cell-label-container": "qQs",
  "cell-label": "qQF",
  "overflow": "qQg",
  "missing": "qQD",
  "credit-note-amount": "qQV",
  "payable-amount": "qQL",
  "amount": "qQT"
};
