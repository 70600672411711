export default {
  "header-cell": "qeQ",
  "first-col": "qee",
  "mid-col": "qeB",
  "outstanding-col": "qeE",
  "header-content": "qed caption-bold",
  "active": "qeI",
  "quick-actions": "qet",
  "empty": "qeM"
};
