export default {
  "modal": "qZy",
  "action-container": "qZU",
  "similar-label": "qZj",
  "info-icon": "qZW",
  "container": "qZu",
  "subtitle": "qRS caption-bold",
  "error": "qRc",
  "item-container": "qRq"
};
