export default {
  "cell": "qRG",
  "supplier-container": "qRr",
  "cell-label-container": "qRb",
  "cell-label": "qRy",
  "overflow": "qRU",
  "sub-label": "qRj body-2",
  "overdue": "qRW",
  "file-name-warning": "qRu",
  "missing": "qQS",
  "pending-icon": "qQc",
  "icon": "qQq",
  "flex-label": "qQZ",
  "with-separator": "qQR",
  "self-invoice-icon": "qQQ",
  "tooltip-wrapper": "qQe"
};
