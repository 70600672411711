export default {
  "modal": "qdL",
  "section": "qdT",
  "header": "qdz",
  "title": "qda",
  "separator-title": "qdH",
  "icon": "qdO",
  "formats": "qdm",
  "columns": "qdp",
  "column-list": "qdx",
  "draggable-column": "qdw",
  "column-field": "qdN",
  "column-selector": "qdv",
  "column-with-error": "qdo",
  "handle": "qdn",
  "label-color": "qdi",
  "disclaimer": "qdA"
};
