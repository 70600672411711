export default {
  "item": "qRZ",
  "checkbox": "qRR",
  "details": "qRQ",
  "flex": "qRe",
  "header": "qRB qRe",
  "flex-no-gap": "qRE qRe",
  "file-name": "qRd",
  "label": "qRI body-2",
  "self-icon": "qRt",
  "einvoice-icon": "qRM",
  "type-value": "qRP",
  "with-separator": "qRl",
  "dimmed": "qRX"
};
