export default {
  "row": "qEs",
  "icon": "qEF",
  "cell": "qEg",
  "cell-date": "qED",
  "cell-amount": "qEV",
  "cell-status": "qEL",
  "cell-content": "qET",
  "align-right": "qEz"
};
