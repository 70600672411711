export default {
  "container": "qMI",
  "header": "qMt",
  "title": "qMM caption-bold",
  "search-icon": "qMP",
  "text": "qMl body-2",
  "trigger": "qMX",
  "loading-state": "qMC",
  "dropzone-placeholder-wrapper": "qMk",
  "dropzone-placeholder": "qMJ",
  "file-loading": "qMs",
  "search-qonto-cta": "qMF",
  "search-qonto-cta-content": "qMg",
  "tooltip-wrapper": "qMD",
  "invoices-tooltip": "qMV"
};
