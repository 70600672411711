export default {
  "insight-pill": "qZE",
  "interactive": "qZd",
  "focus": "qZI",
  "subtitle": "qZt caption",
  "hidden": "qZM",
  "missing-amounts-warning": "qZP",
  "totals": "qZl",
  "counter": "qZX title-3",
  "loading": "qZC",
  "error": "qZk",
  "title": "qZJ title-4"
};
