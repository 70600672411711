export default {
  "row": "qBY",
  "active": "qBh",
  "dot": "qBf",
  "red": "qBK",
  "green": "qBG",
  "yellow": "qBr",
  "align-right": "qBb",
  "animated": "qBy",
  "fadein-item": "qBU",
  "cell": "qBj body-2",
  "cell-content": "qBW",
  "amount": "qBu body-1",
  "subtitle": "qES caption",
  "status": "qEc",
  "cell-amount": "qEq"
};
