export default {
  "progress-container": "qEh",
  "line-container": "qEf",
  "dashed-line": "qEK",
  "solid-line": "qEG",
  "circle-container": "qEr",
  "circle": "qEb",
  "current-step-dot": "qEy",
  "success-icon": "qEU",
  "rejected-icon": "qEj"
};
