export default {
  "insight-pill": "qZs",
  "insight-pill-figure": "qZF",
  "insight-pill-renderer": "qZg",
  "loading": "qZD",
  "counter": "qZV",
  "error": "qZL",
  "insight-pill-label": "qZT",
  "insight-pill-subtitle": "qZz caption",
  "hidden": "qZa",
  "missing-amounts-warning": "qZH",
  "missing-amounts": "qZO"
};
