export default {
  "invoice-sidebar": "cWE",
  "body": "cWd",
  "box": "cWI",
  "box-header": "cWt",
  "sidebar-box": "cWM",
  "border-top": "cWP",
  "row": "cWl",
  "greyed-out": "cWX",
  "strike-through": "cWC"
};
