export default {
  "form": "qqX",
  "form-title": "qqC title2",
  "row": "qqk",
  "col": "qqJ",
  "actions": "qqs",
  "buttons": "qqF",
  "collapsible-timeline": "qqg",
  "link": "qqD",
  "timeline-status-label": "qqV",
  "section": "qqL",
  "einvoicing-disclaimer": "qqT",
  "disclaimer-section": "qqz qqL",
  "related-documents-card-margin-top": "qqa",
  "my-24": "qqH"
};
