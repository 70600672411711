export default {
  "modal": "qdY",
  "section": "qdh",
  "header": "qdf",
  "title": "qdK",
  "icon": "qdG",
  "content": "qdr",
  "export-template-wrapper": "qdb",
  "export-template-radio": "qdy",
  "export-template-content": "qdU",
  "export-template-label": "qdj",
  "export-template-button": "qdW",
  "export-template-list": "qdu",
  "inactive": "qIS",
  "export-template-title": "qIc",
  "export-template-description": "qIq",
  "export-template-item-content": "qIZ",
  "permission-disclaimer-container": "qIR",
  "export-template-footer": "qIQ",
  "export-template-cta-container": "qIe",
  "upsell": "qIB",
  "upsell-section": "qIE",
  "_content": "qId",
  "_cta": "qII",
  "upsell-title": "qIt",
  "upsell-description": "qIM"
};
