export default {
  "custom-export-loading": "qIJ",
  "title": "qIs",
  "description": "qIF",
  "section": "qIg",
  "placeholder-avatar": "qID",
  "_with-avatar": "qIV",
  "_with-list": "qIL",
  "list-item": "qIT",
  "radio": "qIz",
  "header": "qIa",
  "header-line": "qIH"
};
