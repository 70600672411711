export default {
  "team-card": "qEw",
  "card-link": "qEN",
  "shadow": "qEv",
  "history-button": "qEo",
  "cards-button": "qEn",
  "menu-item": "qEi",
  "subtitle": "qEA",
  "delete-team": "qEY"
};
