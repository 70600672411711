export default {
  "row": "qeX",
  "greyed-out": "qeC",
  "supplier-name": "qek",
  "cell": "qeJ",
  "supplier": "qes",
  "email-address": "qeF",
  "outstanding-balance": "qeg",
  "quick-actions": "qeD",
  "empty": "qeV",
  "supplier-container": "qeL",
  "grey-background": "qeT"
};
