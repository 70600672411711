export default {
  "review": "cUO",
  "review-content": "cUm",
  "review-parameters": "cUp",
  "row": "cUx",
  "row__border": "cUw",
  "label": "cUN",
  "value": "cUv",
  "error": "cUo",
  "tooltip-icon": "cUn",
  "terms-link": "cUi"
};
