export default {
  "details": "qcT",
  "details-header": "qcz",
  "details-header-amount": "qca",
  "details-content": "qcH",
  "details-footer": "qcO",
  "invoice-status-container": "qcm body-2",
  "invoice-dates": "qcp",
  "overdue": "qcx",
  "details-list": "qcw",
  "details-list-item": "qcN",
  "details-list-item-label": "qcv body-2",
  "details-list-item-value": "qco",
  "border-top": "qcn",
  "description": "qci",
  "description-value": "qcA",
  "edit-button": "qcY body-2",
  "edit-icon": "qch",
  "badge-union": "qcf",
  "edit-description": "qcK",
  "amount-disclaimer": "qcG",
  "strike-amount": "qcr"
};
