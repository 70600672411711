export default {
  "header": "qSO",
  "subtitle": "qSm body-2",
  "date": "qSp",
  "subtitle-error": "qSx",
  "card": "qSw",
  "card-header": "qSN",
  "line-placeholder": "qSv",
  "post-closing-list": "qSo",
  "post-closing-container": "qSn",
  "post-closing-list-element": "qSi",
  "free-trial-disclaimer": "qSA",
  "description": "qSY body-2",
  "mw-100": "qSh",
  "addon-overview": "qSf",
  "addons-container": "qSK"
};
