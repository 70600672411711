export default {
  "attachment-sidebar": "qcC",
  "sidebar-scroll": "qck",
  "top-section": "qcJ",
  "timeline-section": "qcs",
  "sidebar-header": "qcF",
  "matched-section": "qcg",
  "collapsible-timeline": "qcD",
  "timeline-status-label": "qcV",
  "m-24": "qcL"
};
