export default {
  "row": "cuv",
  "disabled": "cuo",
  "cell": "cun body-2",
  "cell-link": "cui",
  "cell-content": "cuA",
  "item-truncate-text": "cuY",
  "item-name-cell": "cuh",
  "item-account-cell": "cuf",
  "icon": "cuK",
  "icon-download": "cuG"
};
