export default {
  "container": "qto",
  "item": "qtn caption-bold",
  "hover": "qti",
  "active": "qtA",
  "name-wrapper": "qtY",
  "name": "qth body-2",
  "count": "qtf",
  "actions-placeholder": "qtK",
  "actions": "qtG",
  "show": "qtr",
  "action-button": "qtb"
};
