export default {
  "wrapper": "cje",
  "content": "cjB",
  "title": "cjE",
  "aside": "cjd",
  "description": "cjI",
  "faq-link": "cjt",
  "close-btn": "cjM",
  "container": "cjP",
  "mandate-field-list": "cjl",
  "mandate-field-item": "cjX",
  "mandate-field-item__dot": "cjC",
  "mandate-field-item__text": "cjk"
};
