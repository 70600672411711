export default {
  "label-select": "cuC",
  "puce": "cuk",
  "puce-wrapper": "cuJ",
  "puce-container": "cus",
  "readonly": "cuF",
  "label-display": "cug",
  "label-list-name": "cuD body-1",
  "label-list-value": "cuV body-2"
};
