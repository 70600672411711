export default {
  "transaction": "qte",
  "transaction-details": "qtB",
  "transaction-details-amount": "qtE body-1",
  "credit": "qtd",
  "negative-amount": "qtI",
  "transaction-details-description": "qtt body-2",
  "transaction-remove-cta": "qtM",
  "avatar": "qtP mr-16",
  "spinner": "qtl"
};
