export default {
  "sidepanel": "qQv",
  "close-icon": "qQo",
  "close-button": "qQn",
  "supplier-card": "qQi",
  "supplier-card-details": "qQA",
  "supplier-name": "qQY title-3 mb-8",
  "supplier-card-placeholder": "qQh",
  "box": "qQf",
  "box-element": "qQK",
  "related-invoices-title": "qQG",
  "placeholder-container": "qQr",
  "related-invoices-wrapper": "qQb",
  "related-invoice-placeholder": "qQy",
  "related-invoice-placeholder-inner": "qQU",
  "actions": "qQj",
  "currency": "qQW"
};
