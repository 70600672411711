export default {
  "modal": "cjg",
  "container": "cjD",
  "form-container": "cjV",
  "preview-container": "cjL",
  "preview-content": "cjT",
  "switch-container": "cjz",
  "supplier-invoice-preview": "cja",
  "header": "cjH",
  "footer": "cjO",
  "form-content": "cjm",
  "form-content-section": "cjp",
  "form-disclaimer": "cjx",
  "hidden": "cjw"
};
