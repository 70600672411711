export default {
  "savings-account": "cyb",
  "closed": "cyy",
  "badge": "cyU",
  "subtitle": "cyj",
  "amount": "cyW",
  "processing": "cyu",
  "gain": "cUS",
  "active": "cUc",
  "progress": "cUq",
  "progress-bar": "cUZ",
  "progress-text": "cUR",
  "info": "cUQ",
  "menu-item": "cUe body-2",
  "close-account": "cUB"
};
