export default {
  "wrapper": "qdd",
  "section-wrapper": "qdI",
  "header": "qdt",
  "title": "qdM body-1",
  "close-icon": "qdP",
  "section": "qdl",
  "section-title": "qdX caption-bold",
  "labels": "qdC",
  "labels-title": "qdk",
  "section-checkbox": "qdJ",
  "grow": "qds scroll",
  "label": "qdF",
  "buttons": "qdg"
};
