export default {
  "supplier-selector-wrapper": "qqo",
  "select-supplier": "qqn",
  "dropdown": "qqi",
  "iban": "qqA",
  "card": "qqY body-2",
  "card-content": "qqh",
  "add-btn": "qqf btn btn--square btn--icon-only",
  "archived-card": "qqK",
  "title": "qqG body-1",
  "archived-title": "qqr",
  "edit-supplier-tooltip": "qqb",
  "add-supplier": "qqy btn btn--tertiary",
  "error": "qqU caption",
  "form-title": "qqj title2",
  "dropdown-menu": "qqW",
  "menu-item": "qqu body-2 dropdown-overlay",
  "disabled": "qZS",
  "iban-error": "qZc"
};
